import * as React from "react";
import { Layout, SEO, LWrap, CBtnList, CUniqueTitle } from "../components/_index"

// markup
const NotFoundPage = () => {
  return (
    <Layout isKv={false}>
      <SEO
        title="404 not found"
        ogtype={"website"}
      />
      <section className="u_pt120 u_pb120">
        <LWrap>
          <CUniqueTitle title={{ main: '404', sub: 'NOT FOUND' }} />
          <h2 className="c_headingLv2 u_mb15">お探しのページが見つかりません</h2>
          <p className="c_sectLead">お探しのページは一時的にアクセスができない状況にあるか、移動もしくは削除された可能性があります。<br />お手数ではございますが、トップページにて目的のページをお探しください。</p>
          <CBtnList exClass="u_mt40" data={[
            {
              iconBefore: true,
              icon: "arrowReverse",
              label: 'TOPへ戻る',
              link: {
                href: '/',
              },
            }
          ]} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default NotFoundPage;
